import { FormattedMessage } from 'react-intl';

export const Item3 = () => (
  <>
    <h3>
      <FormattedMessage id="collapse.content.item3.1" />
    </h3>
    <p>
      <FormattedMessage id="collapse.content.item3.2" />
    </p>
    <h4>
      <FormattedMessage id="collapse.content.item3.3" />
    </h4>
    <ul>
      <li>
        <FormattedMessage id="collapse.content.item3.4.1" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item3.4.2" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item3.4.3" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item3.4.4" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item3.4.5" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item3.4.6" />
      </li>
    </ul>
    <h4>
      <FormattedMessage id="collapse.content.item3.5" />
    </h4>
    <div className="collapse-styled-list">
      <div className="item">
        <div className="title-container">
          <div className="counter">1</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item3.6.1" />
            </strong>
            &nbsp;-&nbsp;
            <FormattedMessage id="collapse.content.item3.6.2" />
          </div>
        </div>
      </div>

      <div className="item">
        <div className="title-container">
          <div className="counter">2</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item3.6.3" />
            </strong>
            &nbsp;-&nbsp;
            <FormattedMessage id="collapse.content.item3.6.4" />
          </div>
        </div>
      </div>

      <div className="item">
        <div className="title-container">
          <div className="counter">3</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item3.6.5" />
            </strong>
            &nbsp;-&nbsp;
            <FormattedMessage id="collapse.content.item3.6.6" />
          </div>
        </div>
      </div>

      <div className="item">
        <div className="title-container">
          <div className="counter">4</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item3.6.7" />
            </strong>
            &nbsp;-&nbsp;
            <FormattedMessage id="collapse.content.item3.6.8" />
          </div>
        </div>
      </div>
    </div>
  </>
);
