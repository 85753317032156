import { useIntl, FormattedMessage } from 'react-intl';
import ContactUsForm from 'components/ContactUsForm';

const ContactUs = () => {
  const intl = useIntl();

  return (
    <div className="contact-us-container">
      <div className="inner-container" id="contact">
        <h4 className="page-title">
          <FormattedMessage id="contact.title" />
        </h4>
        <div className="content-container">
          <ContactUsForm />
          <div className="content-details">
            <p>
              <FormattedMessage id="contact.text.1" />
              <br />
              <strong>{intl.formatMessage({ id: 'contact.text.3' })}</strong>
              &nbsp;
              {intl.formatMessage({ id: 'contact.text.2' })}
            </p>
            <div className="content-details-list">
              <div className="item">
                <div className="counter">1</div>
                <div className="title">
                  <FormattedMessage id="contact.text.4" />
                </div>
              </div>
              <div className="item">
                <div className="counter">2</div>
                <div className="title">
                  <FormattedMessage id="contact.text.5" />
                </div>
              </div>
              <div className="item">
                <div className="counter">3</div>
                <div className="title">
                  <FormattedMessage id="contact.text.6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
