import { FormattedMessage } from 'react-intl';

export const Item6 = () => (
  <>
    <h3>
      <FormattedMessage id="collapse.content.item6.1" />
    </h3>
    <p>
      <FormattedMessage id="collapse.content.item6.2" />
    </p>
    <p>
      <FormattedMessage id="collapse.content.item6.3.1" />
      <br />
      <FormattedMessage id="collapse.content.item6.3.2" />
    </p>
    <h4>
      <FormattedMessage id="collapse.content.item6.4" />
    </h4>

    <div className="collapse-styled-list">
      <div className="item">
        <div className="title-container">
          <div className="counter">1</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item6.5.1" />
            </strong>
            <br />
            <FormattedMessage id="collapse.content.item6.5.2" />
          </div>
        </div>
      </div>

      <div className="item">
        <div className="title-container">
          <div className="counter">2</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item6.5.3" />
            </strong>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="title-container">
          <div className="counter">3</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item6.5.4" />
            </strong>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="title-container">
          <div className="counter">4</div>
          <div className="text">
            <strong>
              <FormattedMessage id="collapse.content.item6.5.5" />
            </strong>
            <br />
            <FormattedMessage id="collapse.content.item6.5.6" />
          </div>
        </div>
      </div>
    </div>

    <p>
      <strong>
        <FormattedMessage id="collapse.content.item6.6" />
      </strong>
    </p>
  </>
);
