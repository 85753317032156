import { useIntl } from 'react-intl';

const Footer = () => {
  const intl = useIntl();
  return (
    <div className="footer">
      <div className="inner-container">
        <div className="footer-container">
          <div className="item">
            <label>{intl.formatMessage({ id: 'footer.label.address' })}</label>
            <strong>
              {intl.formatMessage({ id: 'footer.label.address.details' })}
            </strong>
          </div>
          <div className="item">
            <label>{intl.formatMessage({ id: 'footer.label.callUs' })}</label>
            <strong>
              <a href="tel: (716) 228-8888">(716) 228-8885</a>
            </strong>
          </div>
          <div className="item">© 2024 AlgoBrains Solution</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
