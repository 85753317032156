import { FormattedMessage } from 'react-intl';

export const Item4 = () => (
  <>
    <h3>
      <FormattedMessage id="collapse.content.item4.1" />
    </h3>
    <p>
      <a
        href="https://www.uscis.gov/citizenship/learn-about-citizenship/should-i-consider-us-citizenship"
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage id="collapse.content.item4.2.1" />
      </a>
      <FormattedMessage id="collapse.content.item4.2.2" />
    </p>
    <h4>
      <FormattedMessage id="collapse.content.item4.3" />
    </h4>
    <ul>
      <li>
        <FormattedMessage id="collapse.content.item4.4.1" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item4.4.2" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item4.4.3" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item4.4.4" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item4.4.5" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item4.4.6" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item4.4.7" />
      </li>
    </ul>
    <p>
      <strong>
        <FormattedMessage id="collapse.content.item4.5" />
      </strong>
    </p>
  </>
);
