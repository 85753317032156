import React from 'react';
import { useNavigate } from 'react-router-dom';

interface LinkWithScrollProps {
  to: string;
  title: string;
  className?: string;
}

const LinkWithScroll: React.FC<LinkWithScrollProps> = ({ to, title, ...props }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    navigate(to);
    const element = document.getElementById(to.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <a href={to} {...props} onClick={handleClick}>
      {title}
    </a>
  );
};

export default LinkWithScroll;