import React, { useContext } from 'react';
import { LocaleContext } from 'context/localeContext';
import { US, RU, ES } from 'country-flag-icons/react/3x2';
import styles from './LocaleSelector.module.scss';

const LocaleSelector: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);

  return (
    <div className={styles.flagContainer}>
      <div
        className={`${styles.flagIcon} ${locale === 'en' ? styles.active : ''}`}
        onClick={() => setLocale('en')}
      >
        <US />
      </div>
      <div
        className={`${styles.flagIcon} ${locale === 'ru' ? styles.active : ''}`}
        onClick={() => setLocale('ru')}
      >
        <RU />
      </div>
      <div
        className={`${styles.flagIcon} ${locale === 'es' ? styles.active : ''}`}
        onClick={() => setLocale('es')}
      >
        <ES />
      </div>
    </div>
  );
};

export default LocaleSelector;
