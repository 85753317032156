import { FormattedMessage } from 'react-intl';

export const Item5 = () => (
  <>
    <h3>
      <FormattedMessage id="collapse.content.item5.1" />
    </h3>
    <p>
      <FormattedMessage id="collapse.content.item5.2" />
    </p>
    <h4>
      <FormattedMessage id="collapse.content.item5.3" />
    </h4>
    <ul>
      <li>
        <FormattedMessage id="collapse.content.item5.4.1" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item5.4.2" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item5.4.3" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item5.4.4" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item5.4.5" />
      </li>
    </ul>
    <h4>
      <FormattedMessage id="collapse.content.item5.5" />
    </h4>
    <ul>
      <li>
        <FormattedMessage id="collapse.content.item5.6.1" />
      </li>
      <li>
        <FormattedMessage id="collapse.content.item5.6.2" />
      </li>
    </ul>
    <p>
      <strong>
        <FormattedMessage id="collapse.content.item5.7" />
      </strong>
    </p>
  </>
);
