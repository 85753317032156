import { FC } from 'react';
import styles from './Separator.module.scss';

interface SeparatorProps {
  color?: 'blue' | 'gold';
}

const Separator: FC<SeparatorProps> = ({ color = 'blue' }) => {
  return (
    <div
      className={`${styles.container} ${
        color === 'blue' ? styles.blue : styles.gold
      }`}
    ></div>
  );
};

export default Separator;
