import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Select,
  message,
  Row,
  Col
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import styles from './ContactUsForm.module.scss';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-cross.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-tick.svg';
import {
  TEMPLATE_ID,
  SERVICE_ID,
  RECAPTCHA_SITE_KEY,
} from 'configs/emailjsConfig';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

interface ClientForm {
  citizenship?: string;
  country_living_in?: string;
  college?: string;
  degree_major?: string;
  years?: string;
  married?: string;
  family_member_names?: string;
  relationship?: string;
  birth_date?: string;
  family_citizenship?: string;
  employed?: string;
  last_job_title?: string;
  degree_major_employment?: string;
  employment_country?: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_phone: string;
  message: string;
  status_expiry?: string;
}

const { TextArea } = Input;
const { Option } = Select;

const formatDate = 'MM-DD-YYYY';
const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_phone: '',
  message: ''
};

const ContactUsForm: React.FC = () => {
  const intl = useIntl();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [formData, setFormData] = useState<ClientForm>(initialValues);

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        if (!recaptchaToken && currentStep === 2) {
          message.error(intl.formatMessage({ id: 'validation.message.errorRecaptcha' }));
          return;
        }

        setFormData({
          ...formData,
          ...values,
        });
        setCurrentStep(currentStep + 1);
      })
      .catch(() => {
        message.error(intl.formatMessage({ id: 'validation.message.requiredFields' }));
      });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleDateChange = (name: string, date: dayjs.Dayjs | null) => {
    setFormData({
      ...formData,
      [name]: date ? date.format(formatDate) : null
    });
  };

  const resetForm = () => {
    form.resetFields();
    setFormData(initialValues);
    setCurrentStep(0);
    setRecaptchaToken(null);
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
  
      if (!recaptchaToken) {
        setLoading(false);
        message.error(intl.formatMessage({ id: 'validation.message.errorRecaptcha' }));
        return;
      }
  
      await emailjs.send(SERVICE_ID, TEMPLATE_ID, {
        ...values,
        ...formData,
        'g-recaptcha-response': recaptchaToken,
      });

      setLoading(false);
      setCurrentStep(3);
    } catch (error) {
      setLoading(false);
      setCurrentStep(4);
    }
  };

  return (
    <div className={styles.container}>
      <Form form={form} layout="vertical" className="contact-us-container">
        {currentStep === 0 && (
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.firstName'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.lastName'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.email'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="mobile_phone"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.phone'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <TextArea
                  rows={7}
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.message'
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {currentStep === 1 && (
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item
                name="citizenship"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.citizenship'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="country_living_in"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.currentlyLiving'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="college">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.college'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="degree_major">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.degree'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="years"
                label={intl.formatMessage({
                  id: 'contactForm.label.numberOfYears'
                })}
              >
                <DatePicker
                  placeholder={formatDate}
                  format={formatDate}
                  onChange={(date) => handleDateChange('years', date)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="married"
                label={intl.formatMessage({ id: 'contactForm.label.married' })}
              >
                <Radio.Group>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.yes' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.yes' })}
                  </Radio>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.no' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.no' })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="family_member_names">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.familyMemberNames'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="relationship">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.relationship'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="birth_date"
                label={intl.formatMessage({
                  id: 'contactForm.label.birthDate'
                })}
              >
                <DatePicker
                  placeholder={formatDate}
                  format={formatDate}
                  onChange={(date) => handleDateChange('birth_date', date)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="family_citizenship">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.citizenship2'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="employed"
                label={intl.formatMessage({ id: 'contactForm.label.employee' })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Radio.Group>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.yes' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.yes' })}
                  </Radio>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.no' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.no' })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="last_job_title">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.jobTitle'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="degree_major_employment"
                label={intl.formatMessage({ id: 'contactForm.label.lastDay' })}
              >
                <DatePicker
                  placeholder={formatDate}
                  format={formatDate}
                  onChange={(date) =>
                    handleDateChange('degree_major_employment', date)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="employment_country">
                <Input
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.country'
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {currentStep === 2 && (
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item
                name="in_us"
                label={intl.formatMessage({
                  id: 'contactForm.label.currentlyInUS'
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Radio.Group>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.yes' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.yes' })}
                  </Radio>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.no' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.no' })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="immigration_status"
                label={intl.formatMessage({
                  id: 'contactForm.label.immigrationStatus'
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Select>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.1'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.1'
                    })}
                  </Option>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.2'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.2'
                    })}
                  </Option>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.3'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.3'
                    })}
                  </Option>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.4'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.4'
                    })}
                  </Option>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.5'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.5'
                    })}
                  </Option>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.6'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.6'
                    })}
                  </Option>
                  <Option
                    value={intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.7'
                    })}
                  >
                    {intl.formatMessage({
                      id: 'contactForm.immigrationStatus.option.7'
                    })}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="status_expiry"
                label={intl.formatMessage({
                  id: 'contactForm.label.currentStatus'
                })}
              >
                <DatePicker
                  placeholder={formatDate}
                  format={formatDate}
                  onChange={(date) => handleDateChange('status_expiry', date)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="denied_visa"
                label={intl.formatMessage({
                  id: 'contactForm.label.deniedVisa'
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'contactForm.required' })
                  }
                ]}
              >
                <Radio.Group>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.yes' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.yes' })}
                  </Radio>
                  <Radio
                    value={intl.formatMessage({ id: 'contactForm.label.no' })}
                  >
                    {intl.formatMessage({ id: 'contactForm.label.no' })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="green_card_application"
                label={intl.formatMessage({
                  id: 'contactForm.label.everApplied'
                })}
              >
                <TextArea
                  rows={4}
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.describe'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="legal_service"
                label={intl.formatMessage({
                  id: 'contactForm.label.legalService'
                })}
              >
                <TextArea
                  rows={4}
                  placeholder={intl.formatMessage({
                    id: 'contactForm.label.describe'
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={onRecaptchaChange}
              />
            </Col>
          </Row>
        )}

        {currentStep === 3 && (
          <div className={styles.messageContainer}>
            <div className={`${styles.image} ${styles.success}`}>
              <SuccessIcon />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>
                {intl.formatMessage({
                  id: 'validation.contactUs.success.title'
                })}
              </div>
              <div className={styles.message}>
                {intl.formatMessage({
                  id: 'validation.contactUs.success.message'
                })}
              </div>
            </div>
            <Button onClick={resetForm} className="button blue">
              {intl.formatMessage({ id: 'contactForm.label.gotIt' })}
            </Button>
          </div>
        )}

        {currentStep === 4 && (
          <div className={styles.messageContainer}>
            <div className={`${styles.image} ${styles.error}`}>
              <ErrorIcon />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'validation.contactUs.error.title' })}
              </div>
              <div className={styles.message}>
                {intl.formatMessage({
                  id: 'validation.contactUs.error.message'
                })}
              </div>
            </div>
            <Button onClick={resetForm} className="button blue">
              {intl.formatMessage({ id: 'contactForm.label.onceAgain' })}
            </Button>
          </div>
        )}

        {currentStep < 3 && (
          <div className="contact-us-button-container">
            {currentStep > 0 && currentStep < 3 && (
              <Button onClick={prev} className="button blue">
                {intl.formatMessage({ id: 'contactForm.label.back' })}
              </Button>
            )}
            {currentStep < 2 && (
              <Button type="primary" className="button blue" onClick={next}>
                {intl.formatMessage({ id: 'contactForm.label.next' })}
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                type="primary"
                className="button gold"
                loading={loading}
                onClick={onSubmit}
              >
                {intl.formatMessage({ id: 'contactForm.label.submit' })}
              </Button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

export default ContactUsForm;
