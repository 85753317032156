import { FormattedMessage } from 'react-intl';

import ProfileImage from 'assets/images/david.png';
import useMediaQuery from 'hooks/useMediaQuery';

const Biography = () => {
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const isTablet = useMediaQuery('(min-width: 560px)');
  const isMobile = useMediaQuery('(max-width: 560px)');

  return (
    <div className="biography-container" id="about">
      <div className="inner-container">
        {!isTablet && (
          <h3 className="biography-name">
            <FormattedMessage id="content.biography.name" />
          </h3>
        )}
        <div className="image-container">
          <img src={ProfileImage} alt="profile" />
        </div>
        <div className="biography-inner-container">
          {isTablet && (
            <h3 className="biography-name">
              <FormattedMessage id="content.biography.name" />
            </h3>
          )}
          {(isDesktop || isMobile) && (
            <p className="biography-text">
              <FormattedMessage id="content.biography.content" />
            </p>
          )}
        </div>
      </div>
      {isTablet && !isDesktop && (
        <p className="biography-text">
          <FormattedMessage id="content.biography.content" />
        </p>
      )}
    </div>
  );
};

export default Biography;
