import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

interface LocaleContextProps {
  locale: string;
  setLocale: (locale: string) => void;
}

const defaultLocale = 'en';
const LocaleContext = createContext<LocaleContextProps>({
  locale: defaultLocale,
  setLocale: () => {}
});

const LocaleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState<string>(defaultLocale);
  const [messages, setMessages] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const storedLocale = localStorage.getItem('locale') || defaultLocale;
    setLocale(storedLocale);
    loadMessages(storedLocale);
  }, []);

  const loadMessages = async (locale: string) => {
    try {
      const messages = await import(
        `../locales/translations/strings-${locale}.json`
      );
      setMessages(messages);
      setLoading(false);
    } catch (error) {
      console.error(
        `Error loading translations for locale "${locale}":`,
        error
      );
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    setLocale(newLocale);
    localStorage.setItem('locale', newLocale);
    setLoading(true);
    loadMessages(newLocale);
  };

  if (loading) {
    return null;
  }

  return (
    <LocaleContext.Provider value={{ locale, setLocale: handleSetLocale }}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleProvider };
