import { useIntl } from 'react-intl';
import LinkWithScroll from 'components/LinkWithScroll';

const NavigationMenu = () => {
  const intl = useIntl();

  return (
    <div className="navigation-menu">
      <div className="inner-container">
        <div className="name">{intl.formatMessage({ id: 'content.name' })}</div>
        <div className="menu">
          <LinkWithScroll to="/about" title={intl.formatMessage({ id: 'menu.label.about' })} />
          <LinkWithScroll to="/services" title={intl.formatMessage({ id: 'menu.label.service' })} />
          <LinkWithScroll to="/contact" title={intl.formatMessage({ id: 'menu.label.contact' })} />
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
