import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import Separator from 'components/Separator';
import * as Content from './Content';

const CollapseContent: FC = () => {
  const intl = useIntl();

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: intl.formatMessage({ id: 'collapse.title.1' }),
      children: <Content.Item1 />
    },
    {
      key: '2',
      label: intl.formatMessage({ id: 'collapse.title.2' }),
      children: <Content.Item2 />
    },
    {
      key: '3',
      label: intl.formatMessage({ id: 'collapse.title.3' }),
      children: <Content.Item3 />
    },
    {
      key: '4',
      label: intl.formatMessage({ id: 'collapse.title.4' }),
      children: <Content.Item4 />
    },
    {
      key: '5',
      label: intl.formatMessage({ id: 'collapse.title.5' }),
      children: <Content.Item5 />
    },
    {
      key: '6',
      label: intl.formatMessage({ id: 'collapse.title.6' }),
      children: <Content.Item6 />
    },
    {
      key: '7',
      label: intl.formatMessage({ id: 'collapse.title.7' }),
      children: <Content.Item7 />
    }
  ];

  return (
    <div className="collapse-menu">
      <div className="inner-container">
        <Separator />
        <div id="services" className="service-container">
          <Collapse
            items={items}
            defaultActiveKey={['1']}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <ArrowIcon
                style={{ transform: isActive ? 'rotate(90deg)' : 'rotate(0)' }}
              />
            )}
          />
        </div>
        <Separator />
      </div>
    </div>
  );
};

export default CollapseContent;
