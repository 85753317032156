import { FormattedMessage } from 'react-intl';
import Separator from 'components/Separator';

const HowCanIHelp = () => (
  <div className="how-can-help">
    <div className="inner-container">
      <div className="separator-container">
        <Separator color="gold" />
      </div>
      <h3>
        <FormattedMessage id="content.howCanIHelp.title" />
      </h3>
      <p>
        <FormattedMessage id="content.howCanIHelp.text" />
      </p>
    </div>
  </div>
);

export default HowCanIHelp;
