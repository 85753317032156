import { useIntl, FormattedMessage } from 'react-intl';

export const Item1 = () => {
  const intl = useIntl();

  return (
    <>
      <h3>
        <FormattedMessage id="collapse.content.item1.1" />
      </h3>
      <p>
        <FormattedMessage id="collapse.content.item1.2" />
      </p>
      <h4>
        <FormattedMessage id="collapse.content.item1.3" />
      </h4>
      <p>
        <FormattedMessage id="collapse.content.item1.4" />
      </p>
      <ul>
        <li>
          <strong>
            {intl.formatMessage({ id: 'collapse.content.item1.5.1.1' })}
          </strong>{' '}
          - {intl.formatMessage({ id: 'collapse.content.item1.5.1.2' })}
        </li>
        <li>
          <strong>
            {intl.formatMessage({ id: 'collapse.content.item1.5.2.1' })}
          </strong>{' '}
          - {intl.formatMessage({ id: 'collapse.content.item1.5.2.2' })}
        </li>
        <li>
          <strong>
            {intl.formatMessage({ id: 'collapse.content.item1.5.3.1' })}
          </strong>{' '}
          - {intl.formatMessage({ id: 'collapse.content.item1.5.3.2' })}
        </li>
      </ul>
      <div className="collapse-visa-container">
        <div>
          <label>
            <FormattedMessage id="collapse.content.item1.6.1.1" />
          </label>
          <h5>
            <FormattedMessage id="collapse.content.item1.6.1.2" />
          </h5>
          <p>
            <FormattedMessage id="collapse.content.item1.6.1.3" />
          </p>
        </div>
        <div>
          <label>
            <FormattedMessage id="collapse.content.item1.6.2.1" />
          </label>
          <h5>
            <FormattedMessage id="collapse.content.item1.6.2.2" />
          </h5>
          <p>
            <FormattedMessage id="collapse.content.item1.6.2.3" />
          </p>
        </div>
        <div>
          <label>
            <FormattedMessage id="collapse.content.item1.6.3.1" />
          </label>
          <h5>
            <FormattedMessage id="collapse.content.item1.6.3.2" />
          </h5>
          <p>
            <FormattedMessage id="collapse.content.item1.6.3.3" />
          </p>
        </div>
      </div>
      <p>
        <strong>
          <FormattedMessage id="collapse.content.item1.7.1" />
        </strong>
        &nbsp;
        <FormattedMessage id="collapse.content.item1.7.2" />
      </p>
    </>
  );
};
