import { FC } from 'react';
import Header from 'components/Header';
import NavigationMenu from 'components/NavigationMenu';
import HeroBanner from 'components/HeroBanner';
import Biography from 'components/Biography';
import HowCanIHelp from 'components/HowCanIHelp';
import CollapseContent from 'components/CollapseContent';
import ContactUs from 'components/ContactUs';
import Footer from 'components/Footer';

const BaseLayout: FC = () => (
  <div className="main-container">
    <Header />
    <NavigationMenu />
    <HeroBanner />
    <Biography />
    <HowCanIHelp />
    <CollapseContent />
    <ContactUs />
    <Footer />
  </div>
);

export default BaseLayout;
