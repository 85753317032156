import { FormattedMessage } from 'react-intl';

export const Item7 = () => (
  <>
    <h3>
      <FormattedMessage id="collapse.content.item7.1" />
    </h3>
    <p>
      <FormattedMessage id="collapse.content.item7.2" />
    </p>
    <h4>
      <FormattedMessage id="collapse.content.item7.3" />
    </h4>
  </>
);
