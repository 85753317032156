import { FormattedMessage } from 'react-intl';

export const Item2 = () => {
  return (
    <>
      <h3>
        <FormattedMessage id="collapse.content.item2.1" />
      </h3>
      <p>
        <FormattedMessage id="collapse.content.item2.2" />
      </p>
      <h4>
        <FormattedMessage id="collapse.content.item2.3" />
      </h4>
      <div className="collapse-styled-list">
        <div className="item">
          <div className="title-container">
            <div className="counter">1</div>
            <div className="text title">
              <FormattedMessage id="collapse.content.item2.4.1" />
            </div>
          </div>
          <div className="content-container">
            <p>
              <FormattedMessage id="collapse.content.item2.4.2" />
            </p>
          </div>
        </div>

        <div className="item">
          <div className="title-container">
            <div className="counter">2</div>
            <div className="text title">
              <FormattedMessage id="collapse.content.item2.5.1" />
            </div>
          </div>
          <div className="content-container">
            <p>
              <FormattedMessage id="collapse.content.item2.5.2" />
            </p>
            <ul>
              <li>
                <strong>
                  <FormattedMessage id="collapse.content.item2.5.3" />
                </strong>
              </li>
              <li>
                <strong>
                  <FormattedMessage id="collapse.content.item2.5.4" />
                </strong>
              </li>
              <li>
                <strong>
                  <FormattedMessage id="collapse.content.item2.5.5" />
                </strong>
              </li>
              <li>
                <strong>
                  <FormattedMessage id="collapse.content.item2.5.6" />
                </strong>
              </li>
            </ul>
            <p>
              <FormattedMessage id="collapse.content.item2.5.7" />
            </p>
            <ul>
              <li>
                <strong>
                  <FormattedMessage id="collapse.content.item2.5.8" />
                </strong>
              </li>
              <li>
                <strong>
                  <FormattedMessage id="collapse.content.item2.5.9" />
                </strong>
              </li>
            </ul>
            <p>
              <FormattedMessage id="collapse.content.item2.5.10" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="collapse.content.item2.5.11" />
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
