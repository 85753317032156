import { useIntl } from 'react-intl';
import LocaleSelector from 'components/LocaleSelector';

const Header = () => {
  const intl = useIntl();
  return (
    <div className="header">
      <div className="inner-container">
        <div className="phone">
          <label>{intl.formatMessage({ id: 'header.label.phone' })}: </label>
          <a href="tel: (716) 228-8888">(716) 228-8885</a>
        </div>
        <LocaleSelector />
      </div>
    </div>
  );
};

export default Header;
