import { useIntl } from 'react-intl';
import LinkWithScroll from 'components/LinkWithScroll';

const HeroBanner = () => {
  const intl = useIntl();

  return (
    <div className="hero-banner">
      <div className="inner-container">
        <div className="hero-text">
          {intl.formatMessage({ id: 'text.heroBanner' })}
        </div>
        <LinkWithScroll
          to="/contact"
          title={intl.formatMessage({ id: 'label.button.contactUs' })}
          className="button gold"
        />
      </div>
    </div>
  );
};

export default HeroBanner;
