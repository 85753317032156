import React from 'react';
import { LocaleProvider } from 'context/localeContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BaseLayout from 'layouts/BaseLayout';
import { PUBLIC_KEY } from 'configs/emailjsConfig';
import emailjs from 'emailjs-com';

const App: React.FC = () => {
  emailjs.init(PUBLIC_KEY);

  return (
    <LocaleProvider>
      <Router>
        <Routes>
          <Route path="/*" element={<BaseLayout />} />
        </Routes>
      </Router>
    </LocaleProvider>
  );
};

export default App;
